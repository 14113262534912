import './index.scss'

const Portfolio = () => {
  return (
    <>
      <div className="container portfolio-page">
        <div className="port-grid">
          <div className="port-box">Still working on this</div>
          <div className="port-box">Still working on this</div>
          <div className="port-box">Still working on this</div>
          <div className="port-box">Still working on this</div>
        </div>
      </div>
    </>
  )
}

export default Portfolio
